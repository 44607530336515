import styles from '../asset/css/message.module.css';
export function Message() {
  return (
    <section className={styles.message}>
      <h3 className={styles.message_h3}>Hey!!</h3>
      <p className={styles.message_p}>
        no desesperes ya pronto estaremos en la red,
        <br /> con muchas cosas nuevas.
      </p>
    </section>
  );
}
