import styles from '../asset/css/footer.module.css';

export function Footer() {
  return (
    <footer className={styles.footer}>
      <p>
        Developed and designed by:{' '}
        <span className={styles.footer_span}>WanTher-VinCe</span> ©2021. All
        rights reserved.
      </p>
    </footer>
  );
}
