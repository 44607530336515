import { Header } from './components/header';
import { Timer } from './components/timer';
import { Message } from './components/message';
import { Footer } from './components/footer';

function App() {
  return (
    <>
      <Header />
      <Timer />
      <Message />
      <Footer />
    </>
  );
}
export default App;
