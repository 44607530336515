import { useState, useEffect, useRef } from 'react';
import { TimerItem } from './timerItem';
import styles from '../asset/css/timer.module.css';

export function Timer() {
  const [timeDay, setTimeDay] = useState('00');
  const [timeHour, setTimeHour] = useState('00');
  const [timeMinute, setTimeMinute] = useState('00');
  const [timeSecond, setTimeSecond] = useState('00');

  let interval = useRef();

  const countdown = () => {
    const countDate = new Date('Jun 24, 2021 11:11:11').getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const gap = countDate - now;

      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;

      const Day = Math.floor(gap / day);
      const Hour = Math.floor((gap % day) / hour);
      const Minute = Math.floor((gap % hour) / minute);
      const Second = Math.floor((gap % minute) / second);

      if (gap < 0) {
        clearInterval(interval.current);
      } else {
        setTimeDay(Day);
        setTimeHour(Hour);
        setTimeMinute(Minute);
        setTimeSecond(Second);
      }
    }, 1000);
  };

  useEffect(() => {
    countdown();
    return () => {
      clearInterval(interval);
    };
  });
  const normal = (t) => {
    return t < 10 ? '0' + t : t;
  };

  return (
    <main className={styles.timer}>
      <TimerItem title="DAY" time={normal(timeDay)} />
      <TimerItem title="HOUR" time={normal(timeHour)} />
      <TimerItem title="MINUTE" time={normal(timeMinute)} />
      <TimerItem title="SECOND" time={normal(timeSecond)} />
    </main>
  );
}
