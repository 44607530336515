import styles from '../asset/css/timer.module.css';

export function TimerItem({ title, time }) {
  return (
    <>
      <div className={styles.timer_item}>
        <p className={styles.timer_item_p}>{title}</p>
        <div className={styles.timer_item_number}>
          <p className={styles.timer_item_number_p}>{time}</p>
        </div>
      </div>
    </>
  );
}
