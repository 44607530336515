import Logo from '../asset/img/logo.png';
import styles from '../asset/css/header.module.css';
export function Header() {
  return (
    <header className={styles.logo}>
      <img className={styles.logo_img} src={Logo} alt="" />
      <h1 className={styles.logo_h1}>WANTHER</h1>
      <h2 className={styles.logo_h2}>WEB DEVELOPER</h2>
    </header>
  );
}
